import React, {useState} from "react";
import '../../styles/document.css';
import Translator from "../../services/Translator";
import DefaultLoader from "../loaders/DefaultLoader";
import axios from "axios";
import {config} from "../../config";
import passportBackground from '../../assets/images/documents/passport.png';


export default function Passport({lang, user, show, setCurrentDocument}: any)
{
    const name = 'passport';
    const [document, setDocument] = useState<any>(null);
    const [loaded, setLoaded] = useState(false);

    if (show && document === null && !loaded) {
        setLoaded(true);
        axios.get(config.api + '/documents/' + name)
            .then(response => {
                setDocument(response.data);
            })
            .catch(response => console.log(response));
    }

    return (
        <div className="card document">
            <div className="card-header" onClick={() => setCurrentDocument(show ? null : name)}>
                {Translator.trans(lang, "documents.passport.name")}
            </div>

            {
                show && document === null ? (
                    <div className="card-body" style={{background: '#ffeecc'}}>
                        <DefaultLoader />
                    </div>
                ) : ''
            }

            {
                show && document !== null ? (
                    <div className="card-body" style={{
                        background: document.type === 'PD' ?
                            'rgb(228,170,102) radial-gradient(circle, rgba(228,170,102,1) 0%, rgba(242,212,179,1) 100%)' :
                            'rgb(255,238,204) radial-gradient(circle, rgba(255,234,144,1) 0%, rgba(255,238,204,1) 100%)'
                    }}>
                        <div style={{margin: '50px auto', textAlign: 'center', marginTop: '150px', marginBottom: '-300px'}}>
                            <img src={passportBackground} alt={Translator.trans(lang, "documents.passport.name")} />
                        </div>

                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-5">
                                    <img src={'data:image/png;base64,' + document.photo}
                                         alt={document.given_names + ' ' + document.surname}
                                         className="img-fluid"
                                    />
                                    {/*<FlagXMK />*/}
                                </div>

                                <div className="col-7">
                                    <strong>{Translator.trans(lang, "documents.passport.surname")}</strong>
                                    {document.surname}

                                    <strong>{Translator.trans(lang, "documents.passport.given_names")}</strong>
                                    {document.given_names}

                                    <strong>{Translator.trans(lang, "documents.passport.date_of_birth")}</strong>
                                    {document.date_of_birth}
                                </div>
                            </div>

                            <div className="row padding-row-or-col">
                                <div className="col-4">
                                    <strong>{Translator.trans(lang, "documents.passport.type")}</strong>
                                    {document.type}
                                </div>

                                <div className="col-4">
                                    <strong>{Translator.trans(lang, "documents.passport.code")}</strong>
                                    {document.code}
                                </div>

                                <div className="col-4">
                                    <strong>{Translator.trans(lang, "documents.passport.number")}</strong>
                                    {document.number}
                                </div>

                                <div className="col padding-row-or-col">
                                    <strong>{Translator.trans(lang, "documents.passport.authority")}</strong>
                                    {document.authority}

                                    <strong>{Translator.trans(lang, "documents.passport.date_of_birth")}</strong>
                                    {document.date_of_birth}

                                    <strong>{Translator.trans(lang, "documents.passport.date_of_issue")}</strong>
                                    {document.date_of_issue}

                                    <strong>{Translator.trans(lang, "documents.passport.date_of_expiry")}</strong>
                                    {document.date_of_expiry}

                                    {
                                        document.hasOwnProperty('representative_office') ?
                                            (
                                                <div className="padding-row-or-col">
                                                    <strong>{Translator.trans(lang, "documents.passport.representative_office")}</strong>
                                                    {document.representative_office}
                                                </div>
                                            )
                                        : ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                ) : ''
            }
        </div>
    );
}
