import React, {useState} from "react";
import '../../styles/xmkbox.css';
import xmkLogo from "../../assets/images/xmk.png";
import DefaultLoader from "../loaders/DefaultLoader";
import axios from "axios";
import {config} from "../../config";
import backgroundImage from "../../assets/images/pattern.png";


export default function Boxes({openBox}: any) {
    const [loadedBoxes, setLoadedBoxes] = useState(false);
    const [loadingBoxes, setLoadingBoxes] = useState(false);
    const [boxes, setBoxes] = useState<any[]>([]);

    if (!loadingBoxes) {
        setLoadingBoxes(true);

        axios.get(config.api + '/xmkbox')
            .then(response => {
                setBoxes(response.data);
                setLoadedBoxes(true);
            })
            .catch(() => {
                setLoadedBoxes(false);
            });
    }

    const getInboxClass = (inbox: any) => {
        console.log(inbox);
        if (inbox.main) {
            return 'bg-light';
        }

        let address = inbox.address.split('@');
        if (address[1] === 'xmk.app' || address[1] === 'gov.xmk') {
            return 'bg-primary';
        }

        return 'bg-light';
    }

    return (
        <div id="xmkbox-boxes">
            <div className="container">
                <div className="row">
                    <div className="col">
                        {
                            loadedBoxes ? (
                                boxes.map((box) => {
                                    return (<div className="card inbox" onClick={() => openBox(box.id)}>
                                        <div className={'card-body ' + getInboxClass(box)} style={{background: 'url(\'' + backgroundImage + '\') repeat top right'}} />
                                        <div className="card-footer">{box.address}</div>
                                    </div>);
                                })
                            ) : <DefaultLoader />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
