import React from "react";
import '../../styles/xmkbox.css';
import Translator from "../../services/Translator";


export default function Thread({inbox, lang, messages}: any) {
    return (
        <div id="xmkbox-inbox" style={{display: (messages === null ? 'none' : 'block')}}>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="table-responsive">
                                {
                                    messages !== null ?
                                        messages.map((message: any, key: number) => {
                                            return (<div className="card" key={key}>
                                                <div className={'card-header' + (message.from !== inbox.address ? ' bg-warning' : '')}>
                                                    {Translator.trans(lang, 'xmkbox.message.from')}: {message.from}<br />
                                                    {Translator.trans(lang, 'xmkbox.message.to')}: {message.from}<br />
                                                    {message.date}
                                                </div>

                                                <div className="card-body">
                                                    {message.content}
                                                </div>
                                            </div>);
                                        })
                                    : ''
                                }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
