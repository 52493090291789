import React, {useState} from "react";
import '../../styles/document.css';
import Translator from "../../services/Translator";
import DefaultLoader from "../loaders/DefaultLoader";
import axios from "axios";
import {config} from "../../config";
import FlagXMK from "../flags/FlagXMK";
import QRCode from "react-qr-code";
import backgroundImage from "../../assets/images/documents/identity-card.png";


export default function IdentityCard({lang, user, show, setCurrentDocument}: any)
{
    const name = 'identity-card';
    const [document, setDocument] = useState<any>(null);
    const [loaded, setLoaded] = useState(false);

    if (show && document === null && !loaded) {
        setLoaded(true);
        axios.get(config.api + '/documents/' + name)
            .then(response => {
                setDocument(response.data);
            })
            .catch(response => console.log(response));
    }

    return (
        <div className="card document">
            <div className="card-header" onClick={() => setCurrentDocument(show ? null : name)}>
                {Translator.trans(lang, "documents.identity_card.name")}
            </div>

            {
                show && document === null ? (
                    <div className="card-body" style={{background: 'rgb(134,170,202) linear-gradient(113deg, rgba(134,170,202,1) 0%, rgba(199,180,124,1) 100%)'}}>
                        <DefaultLoader />
                    </div>
                ) : ''
            }

            {
                show && document !== null ? (
                    <div className="card-body" style={{background: 'rgb(134,170,202) linear-gradient(113deg, rgba(134,170,202,1) 0%, rgba(199,180,124,1) 100%)'}}>
                        <div className="container-fluid" style={{background: 'url(\'' + backgroundImage + '\') repeat top right'}}>
                            <div className="row">
                                <div className="col-5">
                                    <img src={'data:image/png;base64,' + document.photo}
                                         alt={document.given_names + ' ' + document.surname}
                                         className="img-fluid"
                                    />

                                    <FlagXMK />
                                </div>

                                <div className="col-7">
                                    <strong>{Translator.trans(lang, "documents.identity_card.identity_card_number")}</strong>
                                    {document.identity_card_number.substring(0, 3)} {document.identity_card_number.substring(3, 8)}

                                    <strong>{Translator.trans(lang, "documents.identity_card.given_names")}</strong>
                                    {document.given_names}

                                    <strong>{Translator.trans(lang, "documents.identity_card.surname")}</strong>
                                    {document.surname}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <strong>ESEO</strong>
                                    {document.eseo}

                                    <strong>{Translator.trans(lang, "documents.identity_card.date_of_birth")}</strong>
                                    {document.date_of_birth}

                                    <strong>{Translator.trans(lang, "documents.identity_card.date_of_issue")}</strong>
                                    {document.date_of_issue}

                                    <strong>{Translator.trans(lang, "documents.identity_card.expiry_date")}</strong>
                                    {document.expiry_date}

                                    <strong>{Translator.trans(lang, "documents.identity_card.sex")}</strong>
                                    {document.sex}

                                    <div style={{width: '240px', height: '240px', background: 'white', padding: '20px', margin: '20px auto'}}>
                                        <QRCode value={document.qr} style={{width: '200px', height: '200px'}} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : ''
            }
        </div>
    );
}
